var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-10",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    staticClass: "darkGrey--text pa-12",
    class: _vm.$vuetify.theme.dark ? '' : 'cardBackgroud',
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "",
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-icon', {
    staticClass: "mt-n2",
    attrs: {
      "color": "primary",
      "left": "",
      "size": "40"
    }
  }, [_vm._v("mdi-timeline-text-outline ")]), _c('span', [_c('span', {
    staticClass: "headingTitle mr-2 semi-bold"
  }, [_vm._v("Honest Feedback")]), _c('sup', {
    staticClass: "smallFont primary--text"
  }, [_vm._v("LIMITED TIME")])])], 1)])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "mb-3 darkGrey--text",
    staticStyle: {
      "max-width": "43ch"
    }
  }, [_vm._v(" Get honest, professional feedback: Our one-time service offers in-depth app testing by experts to validate your idea, identify bugs, and provide valuable first-impression insights. "), _c('br'), _c('br'), _vm._v(" As we build our "), _c('b', [_vm._v("Crowd Testing")]), _vm._v(" feature, this temporary service is key in gathering essential insights and gauging its future potential within our innovative, user-centric offerings. ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "get_started",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-rocket")]), _vm._v(" Get Started ")], 1), _c('div', {
    staticClass: "smallFont mt-3"
  }, [_c('i', [_vm._v("This service may include one-time charge.")])])], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "cols": "6"
    }
  }, [_c('img', {
    staticStyle: {
      "aspect-ratio": "606 / 446"
    },
    attrs: {
      "src": "/media/svg/honest_feedback_hero.svg",
      "width": "100%"
    }
  })])], 1)], 1)], 1), _c('v-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-row', _vm._l(_vm.featureList, function (feature, index) {
    return _c('v-col', {
      key: index,
      staticClass: "darkGrey--text",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', [_c('v-icon', {
      attrs: {
        "color": "primary",
        "left": ""
      },
      domProps: {
        "textContent": _vm._s(feature.icon)
      }
    }), _c('span', {
      staticClass: "semi-bold title"
    }, [_vm._v(_vm._s(feature.title))])], 1), _c('div', {
      staticClass: "ml-8 mt-2"
    }, [_vm._v(_vm._s(feature.description))])]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-8 darkGrey--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-help-circle")]), _c('span', {
    staticClass: "semi-bold title"
  }, [_vm._v("FAQ")])], 1), _c('v-expansion-panels', {
    staticClass: "mt-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, _vm._l(_vm.faqList, function (item, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "py-2 px-4",
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-icon', {
            staticClass: "ml-4",
            attrs: {
              "left": "",
              "large": ""
            }
          }, [_vm._v("expand_more")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "subTitle darkGrey--text"
    }, [_vm._v(" " + _vm._s(item.question) + " ")])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "darkGrey--text bodyFont",
      domProps: {
        "innerHTML": _vm._s(item.answer)
      }
    })])], 1);
  }), 1)], 1), _c('v-col', {
    staticClass: "my-15",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    staticClass: "pa-15",
    attrs: {
      "flat": "",
      "outlined": _vm.$vuetify.theme.dark
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "semi-bold titleFont primary--text"
  }, [_vm._v(" Join our mission ")]), _c('div', {
    staticClass: "semi-bold headingTitle primary--text"
  }, [_vm._v(" make better apps. ")]), _c('div', {
    staticClass: "my-4"
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": "",
      "rounded": "",
      "href": "https://community.testapp.io",
      "target": "_blank"
    }
  }, [_vm._v(" Join our Community ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/media/svg/".concat(_vm.$vuetify.theme.dark ? 'timeline_dark' : 'timeline', ".svg")
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }