<template>
  <v-row justify="center">
    <v-col class="mt-10" cols="12" md="9">
      <v-card
        :outlined="$vuetify.theme.dark"
        :class="$vuetify.theme.dark ? '' : 'cardBackgroud'"
        class="darkGrey--text pa-12"
        color=""
        flat
      >
        <v-row align="center">
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <div>
                  <v-icon class="mt-n2" color="primary" left size="40"
                    >mdi-timeline-text-outline
                  </v-icon>
                  <span>
                    <span class="headingTitle mr-2 semi-bold"
                      >Honest Feedback</span
                    >
                    <sup class="smallFont primary--text">LIMITED TIME</sup>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-3 darkGrey--text" style="max-width: 43ch">
                  Get honest, professional feedback: Our one-time service offers
                  in-depth app testing by experts to validate your idea,
                  identify bugs, and provide valuable first-impression insights.
                  <br /><br />
                  As we build our <b>Crowd Testing</b> feature, this temporary
                  service is key in gathering essential insights and gauging its
                  future potential within our innovative, user-centric
                  offerings.
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  id="get_started"
                  color="primary"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                >
                  <v-icon left>mdi-rocket</v-icon>
                  Get Started
                </v-btn>
                <div class="smallFont mt-3">
                  <i>This service may include one-time charge.</i>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="text-right" cols="6" style="display: flex">
            <img
              src="/media/svg/honest_feedback_hero.svg"
              style="aspect-ratio: 606 / 446"
              width="100%"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col class="mt-5" cols="12" md="9">
      <v-row>
        <v-col
          v-for="(feature, index) in featureList"
          :key="index"
          cols="12"
          class="darkGrey--text"
          md="6"
        >
          <div>
            <v-icon color="primary" left v-text="feature.icon"></v-icon>
            <span class="semi-bold title">{{ feature.title }}</span>
          </div>
          <div class="ml-8 mt-2">{{ feature.description }}</div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="9">
      <div class="mt-4 mb-8 darkGrey--text">
        <v-icon color="primary" left>mdi-help-circle</v-icon>
        <span class="semi-bold title">FAQ</span>
      </div>
      <v-expansion-panels flat outlined class="mt-2">
        <v-expansion-panel v-for="(item, index) in faqList" :key="index">
          <v-expansion-panel-header class="py-2 px-4">
            <div class="subTitle darkGrey--text">
              {{ item.question }}
            </div>
            <template #actions>
              <v-icon left large class="ml-4">expand_more</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="darkGrey--text bodyFont" v-html="item.answer"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col class="my-15" cols="12" md="9">
      <v-card flat :outlined="$vuetify.theme.dark" class="pa-15">
        <v-row align="center">
          <v-col cols="12" md="4">
            <div class="semi-bold titleFont primary--text">
              Join our mission
            </div>
            <div class="semi-bold headingTitle primary--text">
              make better apps.
            </div>
            <div class="my-4">
              <v-btn
                color="primary"
                class="text-transform-none"
                outlined
                rounded
                href="https://community.testapp.io"
                target="_blank"
              >
                Join our Community
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <v-img
              :src="`/media/svg/${
                $vuetify.theme.dark ? 'timeline_dark' : 'timeline'
              }.svg`"
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      featureList: [
        {
          title: "Idea Validation",
          icon: "mdi-update",
          description:
            "We'll help you check if your app idea is a hit! Our team dives in to see if your concept has the potential users are looking for.",
        },
        {
          title: "Bugs",
          icon: "mdi-bug-check-outline",
          description:
            "No one likes bugs! We'll be the detectives, finding and reporting any tech hiccups or issues lurking in your app.",
        },
        {
          title: "First-Time Impression",
          icon: "mdi-lightning-bolt-outline",
          description:
            "Ever wonder what users think the first time they open your app? We'll give you the lowdown on their initial experience—making sure it's a WOW moment.",
        },
        {
          title: "Suggestions",
          icon: "mdi-speaker-message",
          description: `Think of us as your app's personal coach.<br />
            We'll provide friendly advice on how to make your app even better—whether it's tweaking features, design, or overall functionality.`,
        },
      ],
      faqList: [
        {
          question: "What exactly is included in the Honest Feedback service?",
          answer:
            "Our service provides comprehensive app testing by experts, including idea validation, bug identification, and insights on the crucial first impression of your app.",
        },
        {
          question:
            "How is this one-time service different from ongoing crowd testing?",
          answer: `This one-time service is a focused dive into your app's strengths and areas for improvement.<br />
            It's a key step as we develop our Crowd Testing feature, gathering essential insights for future offerings.`,
        },
        {
          question: "Who are the experts testing my app?",
          answer:
            "A selected squad from the team who created TestApp.io will provide the focused dive into your app.",
        },
        {
          question:
            "Why is this service temporary, and how does it contribute to future offerings?",
          answer: `This temporary service is a crucial phase in shaping and supporting Crowd Testing feature.<br />
            Your support provides essential insights, the validation and funding needed to make this service a success.`,
        },
        {
          question: "Can I provide feedback on the testing experience?",
          answer:
            "Absolutely! Your feedback is valuable. It helps us improve and tailor our services, ensuring they meet your needs and expectations.",
        },
        {
          question:
            "How do I get started with this one-time app testing service?",
          answer:
            "Simply reach out to us to express your interest, and we'll guide you through the process 🚀",
        },
      ],
    };
  },
};
</script>
<style scoped>
.cardBackgroud {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: url(/media/background/whiteboard-bg.png),
    lightgray 0px -0.797px / 100.241% 99.72% no-repeat;
  border-radius: 30px !important;
}
</style>
